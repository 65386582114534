import { Box, Button, Link, IconButton, styled, css } from '@mui/material';

import MailIcon from '@/assets/icons/mail-icon.svg?react';

export const footerLinks = [
  { title: 'Über das Projekt', to: '/ueber-das-projekt' },
  { title: 'Datenschutz', to: '/datenschutz' },
  { title: 'Impressum', to: '/impressum' },
];

const StyledFooter = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('md')} {
      display: block;
      margin-top: auto;
      background-color: ${theme.palette.primary.main};
      padding: ${theme.spacing(2)};
    }
  `,
);

const StyledNav = styled(Box)(css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`);

const StyledButton = styled(Button)(css`
  font-size: 0.65rem;
  text-transform: uppercase;
  border: none;
`) as typeof Link;

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
) as typeof Link;

export function Footer() {
  return (
    <StyledFooter component="footer">
      <StyledNav component="nav">
        <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0, margin: 0 }}>
          {footerLinks.map(({ title, to }) => (
            <Box component="li" key={title} sx={{ lineHeight: 0 }}>
              {to && (
                <StyledButton component={Link} href={to}>
                  {title}
                </StyledButton>
              )}
            </Box>
          ))}
        </Box>
        <StyledIconButton component={Link} href="mailto:info@ivm-rheinmain.de">
          <MailIcon />
        </StyledIconButton>
      </StyledNav>
    </StyledFooter>
  );
}
