import { ReactNode } from 'react';
import { Box, css, IconButton, styled } from '@mui/material';

import CloseIcon from '@/assets/icons/cross.svg?react';

const Root = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.spacing(3)};
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.text.secondary};
    border: none;
    box-shadow: none;
  }
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
    color: ${theme.palette.text.secondary};
  }
  `,
);
type PopupPaperProps = { children: ReactNode; onClose?: () => void };

export function PopupContent({ children, onClose }: PopupPaperProps) {
  return (
    <Root>
      {onClose && (
        <CloseButton aria-label="Schließen" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}

      {children}
    </Root>
  );
}
