import { LevelOfServiceLayer } from '@/components/map/LevelOfServiceLayer';
import { TrafficReportLayer } from '@/components/map/TrafficReportLayer';
import { useMapStore } from '@/stores/useMapStore';

export function TrafficReportLayers() {
  const { trafficReport } = useMapStore((state) => state.layersControl);

  return trafficReport ? (
    <>
      <LevelOfServiceLayer />
      <TrafficReportLayer />
    </>
  ) : null;
}
