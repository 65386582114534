import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Feature, Geometry } from 'geojson';

type PopupDatePeriodProps = { feature: Feature<Geometry, { valid_from?: string; valid_to?: string }> };

export function PopupDatePeriod({ feature }: PopupDatePeriodProps) {
  const from = feature.properties.valid_from;
  const to = feature.properties.valid_to;

  return to || from ? (
    <Typography mb={1}>
      {from && !to && 'Ab '}
      {from && dayjs(from).format('L')}

      {from && to && ' – '}

      {!from && to && 'Bis '}
      {to && dayjs(to).format('L')}
    </Typography>
  ) : null;
}
