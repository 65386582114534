import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development';
const release =
  import.meta.env.VITE_SENTRY_RELEASE_VERSION && `vielmobil-frontend@${import.meta.env.VITE_SENTRY_RELEASE_VERSION}`;

Sentry.init({
  dsn: 'https://d8344fdba4984f3f967a0bb7be8f64a8@sentry.trafficon.eu/7',
  enabled: environment !== 'development',
  environment,
  ...(release && { release }),
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
