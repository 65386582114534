import { Box, styled, css } from '@mui/material';
import { Link } from 'react-router-dom';

import Logo from '@/assets/logos/vielmobil.svg?react';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up('md')} {
      // Todo double display
      display: block;
      background: ${theme.palette.primary.main};
      padding: ${theme.spacing(2)};
      display: flex;
    }
  `,
);

export function Header() {
  return (
    <StyledBox>
      <Link to="/" style={{ display: 'block' }}>
        <Logo style={{ display: 'block' }} title="Vielmobil" />
      </Link>
    </StyledBox>
  );
}
