import dayjs from 'dayjs';
import 'dayjs/locale/de';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.locale('de');
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(duration);
