import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { deDE } from '@mui/material/locale';
import { alpha, css } from '@mui/material';
import '@fontsource/raleway/latin-400.css';
import '@fontsource/raleway/latin-700.css';

let themeBuilder = createTheme(
  {
    palette: {
      primary: {
        main: '#0082d7',
        light: '#009fe3',

        contrastText: '#ffffff',
      },

      secondary: {
        main: '#ffffff',

        contrastText: '#0082d7',
      },

      text: {
        primary: '#ffffff',
        secondary: '#000000',
      },

      action: {
        active: '#ffffff',
      },

      error: {
        main: '#e20613',
      },
      success: {
        main: '#57ad00',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['"Raleway"', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },
      h1: {
        fontFamily: ['"Raleway"', 'sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: '1.8rem',
      },
      h2: {
        fontFamily: ['"Raleway"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      '0 0.2px 0.4px rgba(0, 0, 0, 0.017), 0 0.5px 1.1px rgba(0, 0, 0, 0.024), 0 1px 2px rgba(0, 0, 0, 0.03), 0 1.8px 3.6px rgba(0, 0, 0, 0.036), 0 3.3px 6.7px rgba(0, 0, 0, 0.043), 0 8px 16px rgba(0, 0, 0, 0.06)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  deDE,
);

themeBuilder = createTheme(themeBuilder, {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: css`
          background-color: ${themeBuilder.palette.primary.main};
          box-shadow: none;
        `,
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: css`
          &::before,
          &:hover:not(.Mui-disabled)::before {
            border-bottom-color: ${alpha(themeBuilder.palette.common.white, 0.42)};
          }

          &::after {
            border-bottom-color: ${themeBuilder.palette.common.white};
          }
        `,
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: css`
          background-color: ${themeBuilder.palette.primary.main};
        `,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: css`
          color: ${themeBuilder.palette.common.white};
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: css`
          border-radius: ${themeBuilder.spacing(2.5)};
          border: 2px solid ${themeBuilder.palette.common.white};
          text-transform: none;
          font-weight: bold;
          font-size: 0.8rem;
          color: ${themeBuilder.palette.common.white};
        `,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },

        '#root': {
          height: '100%',
        },
        '.mapboxgl-popup-content': {
          padding: 0,
          background: '#ffffff',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

themeBuilder = responsiveFontSizes(themeBuilder);

export const theme = themeBuilder;
