import { Typography } from '@mui/material';

type PageTitleProps = {
  title: string;
};

export function StyledPageTitle({ title }: PageTitleProps) {
  return (
    <Typography variant="h1" textTransform="uppercase">
      {title}
    </Typography>
  );
}
