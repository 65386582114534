import { Box, Typography, styled, css } from '@mui/material';
import dayjs from 'dayjs';

import { RoutePropertyService } from '@/modules/RoutePropertyService';
import { Duration } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { useRoutesStore } from '@/stores/useRoutesStore';

const StyledCollapseSummary = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.primary.light};
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.common.white};
  `,
);

const manipulateDuration = (duration: Duration, mode: Mode): Duration => {
  if (mode === Mode.WALK) {
    const minutes = dayjs.duration(duration).asMinutes() * 3;

    return dayjs.duration({ minutes }).toISOString() as Duration;
  }

  return duration;
};

type ResultSummaryProps = {
  publicMode: boolean;
  duration: Duration | undefined;
  departure?: string;
  arrival?: string;
  interchange?: number;
  changeModes?: (string | undefined)[] | undefined;
  distance?: number;
  index?: number;
  algorithm?: string;
};

export function ResultSummary({
  publicMode,
  duration,
  departure,
  arrival,
  interchange,
  changeModes,
  distance,
  index,
  algorithm,
}: ResultSummaryProps) {
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const { updateSelectedMode } = useRoutesStore((state) => state.actions);

  return (
    <StyledCollapseSummary
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        updateSelectedMode({ index });
      }}
    >
      {publicMode ? (
        <Box>
          <StyledTypography>
            via {changeModes?.join(', ')} | Ankunft {RoutePropertyService.getTimeFromISO(arrival)}
          </StyledTypography>
        </Box>
      ) : (
        <Box>
          <StyledTypography>Routing Modus: {algorithm}</StyledTypography>
        </Box>
      )}

      {publicMode && selectedMode.mode ? (
        <Box>
          <StyledTypography>
            {duration && RoutePropertyService.duration(manipulateDuration(duration, selectedMode.mode))} | {interchange}{' '}
            {interchange === 1 ? 'Umstieg' : 'Umstiege'}
          </StyledTypography>
          <StyledTypography>Abfahrt {RoutePropertyService.getTimeFromISO(departure)}</StyledTypography>
        </Box>
      ) : (
        <Box>
          <StyledTypography>
            {duration &&
              selectedMode.mode &&
              RoutePropertyService.duration(manipulateDuration(duration, selectedMode.mode))}{' '}
            | {RoutePropertyService.getReadableDistance(distance)}
          </StyledTypography>
        </Box>
      )}
    </StyledCollapseSummary>
  );
}
