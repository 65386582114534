import { RouteLocationType } from '@/types/RouteLocationType';
import originMarker from '@/assets/icons/map/route-locations/origin.svg?url';
import destinationMarker from '@/assets/icons/map/route-locations/destination.svg?url';

type RouteLocationMarkerImageProps = {
  markerType: RouteLocationType;
};

export function RouteLocationMarkerImage({ markerType }: RouteLocationMarkerImageProps) {
  return (
    <img
      src={{ [RouteLocationType.ORIGIN]: originMarker, [RouteLocationType.DESTINATION]: destinationMarker }[markerType]}
      alt=""
    />
  );
}
