import { Box, styled, css, Link, Button } from '@mui/material';
import dayjs from 'dayjs';

import { ResultDetails } from '@/components/routing/ResultDetails';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';
import StrategyIcon from '@/assets/icons/map/strategy.svg?react';
import { Duration } from '@/modules/api-routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useRoutes } from '@/hooks/useRoutes';

const StyledBox = styled(Box)(
  ({ theme }) => css`
	  width: 100%;
	  background-color: ${theme.palette.primary.main};
	  text-align: center;

	  ${theme.breakpoints.down('md')} {
		background-color: ${theme.palette.primary.light};
	  }
	  }
	  `,
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
		margin-bottom: ${theme.spacing(2)};
		
		&.MuiButtonBase-root:hover {
			background-color: ${theme.palette.common.white};
			color: ${theme.palette.primary.main};
		  }
		}
		`,
) as typeof Link;

const manipulateDuration = (duration: Duration, mode: Mode | undefined): Duration => {
  if (mode === Mode.WALK) {
    const minutes = dayjs.duration(duration).asMinutes() * 3;

    return dayjs.duration({ minutes }).toISOString() as Duration;
  }

  return duration;
};

const manipulateArrival = (duration: Duration, startTime: string, mode: Mode | undefined): string =>
  dayjs(startTime)
    .add(dayjs.duration(manipulateDuration(duration, mode)))
    .toISOString();

type ResultDetailsWrapperProps = { route: any; mobile?: boolean };

export function ResultDetailsWrapper({ route, mobile }: ResultDetailsWrapperProps) {
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const date = useRoutesStore((state) => state.time.date);

  const routes = useRoutes();

  const formattedDate = dayjs(date).format('DD.MM.YYYY');

  const routeData =
    !mobile &&
    (selectedMode.mode === Mode.PUBLIC_TRANSPORT ||
      selectedMode.mode === Mode.BIKE_AND_RIDE ||
      selectedMode.mode === Mode.PARK_AND_RIDE)
      ? route.trip
      : route;

  const alternativeRouteData =
    selectedMode.mode &&
    routes[selectedMode.mode].data?.serviceDelivery?.deliveryPayload?.individualRouteResponse
      ?.individualRouteResponseContext?.situations?.roadSituation;

  return (
    <Box>
      {selectedMode.mode === Mode.PUBLIC_TRANSPORT ||
      selectedMode.mode === Mode.BIKE_AND_RIDE ||
      selectedMode.mode === Mode.PARK_AND_RIDE ? (
        routeData?.tripLeg?.map((timedLeg: any, index: number) => {
          const tripLegLength = routeData?.tripLeg?.length;

          let modeIcon;

          if (selectedMode.mode === Mode.BIKE_AND_RIDE) {
            modeIcon = Mode.CYCLE;
          } else if (selectedMode.mode === Mode.PARK_AND_RIDE) {
            modeIcon = Mode.CAR;
          } else {
            modeIcon = Mode.WALK;
          }

          if (index === 0) {
            return (
              <ResultDetails
                key={timedLeg.legId}
                time={routeData?.startTime}
                icon={<ModeIcon mode={modeIcon} />}
                place={origin?.properties.name.full}
                duration={timedLeg.continuousLeg?.duration}
                distance={routeData?.tripLeg?.[0].continuousLeg?.length}
              />
            );
          }

          if (timedLeg.interchangeLeg && timedLeg.interchangeLeg.interchangeMode !== 'WALK') {
            return (
              <ResultDetails
                key={timedLeg.interchangeLeg.timeWindowStart}
                time={timedLeg.interchangeLeg.timeWindowStart}
                icon={<ModeIcon mode={selectedMode.mode} />}
                place={timedLeg.interchangeLeg.legStart.locationName.text}
                duration={timedLeg.interchangeLeg.duration}
                distance={timedLeg.interchangeLeg.length}
              />
            );
          }
          if (timedLeg.interchangeLeg && timedLeg.interchangeLeg.interchangeMode === 'WALK') {
            return null;
          }

          if (timedLeg?.timedLeg && tripLegLength && index > 0 && index < tripLegLength - 1) {
            return (
              <ResultDetails
                key={timedLeg.legId}
                publicMode
                time={timedLeg.timedLeg?.legBoard?.serviceDeparture?.timetabledTime}
                icon={<ModeIcon mode={Mode.PUBLIC_TRANSPORT} />}
                place={timedLeg.timedLeg?.legBoard?.stopPointName?.text}
                changeMode={timedLeg.timedLeg?.service?.publishedLineName?.text}
                changePlace={timedLeg.timedLeg?.service?.destinationText?.text}
              />
            );
          }

          return (
            <Box key={timedLeg.legId}>
              <ResultDetails
                time={timedLeg?.continuousLeg?.timeWindowStart}
                icon={<ModeIcon mode={Mode.WALK} />}
                place={timedLeg.continuousLeg?.legStart?.locationName?.text}
                duration={timedLeg?.continuousLeg?.duration}
                distance={timedLeg.continuousLeg?.length}
              />
              <ResultDetails
                time={timedLeg.continuousLeg?.timeWindowEnd}
                place={timedLeg.continuousLeg?.legEnd?.locationName?.text}
              />
              {selectedMode.mode === Mode.PUBLIC_TRANSPORT && (
                <StyledBox>
                  <StyledButton
                    component={Link}
                    href="https://www.rmv.de/auskunft/bin/jp/query.exe/dn"
                    underline="none"
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    Auskunft
                  </StyledButton>
                </StyledBox>
              )}
            </Box>
          );
        })
      ) : (
        <Box>
          <Box>
            <ResultDetails
              time={routeData?.startTime}
              icon={<ModeIcon mode={selectedMode.mode} />}
              place={origin?.properties.name.full}
              duration={manipulateDuration(routeData?.duration, selectedMode.mode)}
              distance={routeData?.distance}
            />
            {alternativeRouteData &&
              alternativeRouteData.map((alternativeRoute) => (
                <ResultDetails
                  key={alternativeRoute.situationNumber?.value}
                  icon={<StrategyIcon style={{ width: 36, height: 36 }} />}
                  alternativeRouteDescription={alternativeRoute?.description?.value}
                  alternativeRouteDetails={alternativeRoute?.detail?.value}
                />
              ))}
          </Box>
          <ResultDetails
            time={manipulateArrival(routeData.duration, routeData.startTime, selectedMode.mode)}
            place={destination?.properties.name.full}
          />
          <StyledBox>
            {selectedMode.mode === Mode.CYCLE && (
              <StyledButton
                component={Link}
                href={`https://radroutenplaner.hessen.de/map/?sharelink=1&lng=&prm=1&spt=${origin?.geometry.coordinates[0]},${origin?.geometry.coordinates[1]}&ept=${destination?.geometry.coordinates[0]},${destination?.geometry.coordinates[1]}&tre=false&date=${formattedDate}&bev=EntireBicycleNetwork&stg=No&oa=&on=&ow=&ou=&oun=&fer=false&mtb=true`}
                underline="none"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                Auskunft
              </StyledButton>
            )}
          </StyledBox>
        </Box>
      )}
    </Box>
  );
}
