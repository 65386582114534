import { Fragment, useState } from 'react';
import {
  AppBar,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  styled,
  css,
  Link as HyperLink,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import VielmobilLogo from '@/assets/logos/vielmobil.svg?react';
import Menu from '@/assets/icons/menu.svg?react';
import MailIcon from '@/assets/icons/mail-icon.svg?react';
import PopOverIcon from '@/assets/icons/chevron-up-circle.svg?react';
import { footerLinks } from '@/components/Footer';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    display: block;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `,
) as typeof IconButton;

const StyledAppBar = styled(AppBar)(
  () => css`
    z-index: 100;
  `,
);

const StyledToolbar = styled(Toolbar)(
  () => css`
    display: flex;
    justify-content: space-between;
  `,
);

const StyledSwipeableDrawer = styled(SwipeableDrawer)(
  () => css`
    & .MuiPaper-root {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    text-transform: uppercase;
    font-weight: bold;
  `,
);

const StyledListItemText = styled(ListItemText)(
  () => css`
    text-align: center;

    & .MuiTypography-root {
      font-weight: bold;
    }
  `,
);

export function MobileNavigation() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawerOpen = () => setDrawerOpen((prevState) => !prevState);

  const location = useLocation();

  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down('md'));

  return show ? (
    <>
      <StyledAppBar position="relative" elevation={1}>
        <StyledToolbar>
          <Link to="/">
            <VielmobilLogo title="www.vielmobil.info" />
          </Link>

          <StyledIconButton onClick={toggleDrawerOpen} edge="end" color="inherit" aria-label="Menü öffnen">
            <Menu />
          </StyledIconButton>
        </StyledToolbar>
      </StyledAppBar>

      <StyledSwipeableDrawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawerOpen}
        onOpen={toggleDrawerOpen}
        disableSwipeToOpen
      >
        <IconButton onClick={toggleDrawerOpen} sx={{ marginBottom: 3 }}>
          <PopOverIcon />
        </IconButton>
        <Link to="/" style={{ marginBottom: 3 }}>
          <VielmobilLogo />
        </Link>
        <List>
          {footerLinks
            .filter(({ to }) => location.pathname !== '/' || to !== '/')
            .map(({ title, to }) => (
              <Fragment key={to}>
                {to && (
                  <ListItemButton component={Link} to={to}>
                    <StyledListItemText primary={title} />
                  </ListItemButton>
                )}
              </Fragment>
            ))}
        </List>
        <IconButton component={HyperLink} href="mailto:info@ivm-rheinmain.de" mt={4}>
          <MailIcon />
        </IconButton>
      </StyledSwipeableDrawer>
    </>
  ) : null;
}
