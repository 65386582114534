import { useMap } from 'react-map-gl';

type UseMapImagesProps = {
  images: { name: string; url: string; width?: number; height?: number }[];
};

export const useMapImages = ({ images }: UseMapImagesProps) => {
  const { current: map } = useMap();

  if (map) {
    images.forEach(({ name, url, width, height }) => {
      if (!map.hasImage(name)) {
        const extension = url.split('.').pop();

        if (extension === 'svg') {
          const img = new Image(2 * width!, 2 * height!);
          img.onload = () => !map.hasImage(name) && map.addImage(name, img);
          img.src = url;
        } else {
          map.loadImage(url, (error, image) => {
            if (error) throw error;
            if (!map.hasImage(name)) {
              map.addImage(name, image as ImageBitmap);
            }
          });
        }
      }
    });
  }
};
