import { Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { TrafficInfoBoardFeature } from '@/types/TrafficInfoBoardFeature';

type TrafficInfoBoardProps = {
  feature?: TrafficInfoBoardFeature;
  onClose: () => void;
};

export function TrafficInfoBoardPopup({ feature, onClose }: TrafficInfoBoardProps) {
  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;

  const description = feature.properties.content?.replace('{}', '').replace('{"', '').replace('"}', '');

  return (
    <Popup
      key={feature.properties.id}
      longitude={longitude}
      latitude={latitude}
      onClose={onClose}
      offset={18}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent onClose={onClose}>
        <PopupTitle>{feature.properties.id}</PopupTitle>
        <PopupDatePeriod feature={feature} />

        {description && <Typography>{description}</Typography>}
      </PopupContent>
    </Popup>
  );
}
