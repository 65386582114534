/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GeocoderFeature = {
  geometry: {
    coordinates: Array<number>;
    type: GeocoderFeature.type;
  };
  type: string;
  properties: {
    osm_id?: number;
    osm_type?: string;
    extent?: Array<number>;
    country?: string;
    osm_key?: string;
    city?: string;
    osm_value?: string;
    postcode?: string;
    name?: string;
    state?: string;
    street?: string;
    housenumber?: string;
  };
};

export namespace GeocoderFeature {
  export enum type {
    POINT = 'Point',
  }
}
