import { TriasService } from '@/modules/TriasService';
import { RoutingAPI, Trias } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';
import { RoutesState } from '@/types/RoutesState';

export class Routing {
  public static get(parameters: {
    origin: RouteLocationFeature;
    destination: RouteLocationFeature;
    time: RoutesState['time'];
    mode: Mode;
  }) {
    const { mode } = parameters;

    const routeRequest: Trias = TriasService.createRouteRequest(parameters);

    if (mode === Mode.WALK || mode === Mode.CYCLE || mode === Mode.CAR) {
      return RoutingAPI.getIndividualRoutes({
        triasRequest: routeRequest,
      });
    }

    if (mode === Mode.PUBLIC_TRANSPORT || mode === Mode.BIKE_AND_RIDE || mode === Mode.PARK_AND_RIDE) {
      return RoutingAPI.getIntermodalTrips({
        triasRequest: routeRequest,
      });
    }

    return undefined;
  }
}
