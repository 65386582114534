import { Layer, Source } from 'react-map-gl';

import { GeoServer } from '@/modules/GeoServer';
import TrafficInfoBoardIcon from '@/assets/icons/map/traffic-report/traffic-info-board.svg?url';
import { useMapImages } from '@/hooks/useMapImages';

export const TRAFFIC_INFO_BOARD_LAYER = 'TRAFFIC_INFO_BOARD_LAYER';
const TRAFFIC_INFO_BOARD_ICON = 'TRAFFIC_INFO_BOARD_ICON';

export function TrafficInfoBoardLayer() {
  useMapImages({
    images: [{ name: TRAFFIC_INFO_BOARD_ICON, url: TrafficInfoBoardIcon, width: 36, height: 36 }],
  });

  return (
    <Source
      key={TRAFFIC_INFO_BOARD_LAYER}
      id={TRAFFIC_INFO_BOARD_LAYER}
      type="geojson"
      filter={['to-boolean', ['get', 'content']]}
      data={GeoServer.wfs({ typeName: 'signs_view' })}
    >
      <Layer
        id={TRAFFIC_INFO_BOARD_LAYER}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-size': 0.5,
          'icon-image': TRAFFIC_INFO_BOARD_ICON,
        }}
      />
    </Source>
  );
}
