import { Box } from '@mui/material';

import { Mode } from '@/types/Mode';
import { RouteCollapse } from '@/components/routing/RouteCollapse';
import { TriasService } from '@/modules/TriasService';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useRoutes } from '@/hooks/useRoutes';

type RoutingResultsProp = { showDetails?: () => void };

export function RoutingResults({ showDetails }: RoutingResultsProp) {
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const routes = useRoutes();

  const individualRoute = selectedMode.mode && TriasService.getIndividualRoute(routes[selectedMode.mode].data);
  const publicRoutes =
    selectedMode.mode && routes[selectedMode.mode].data?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult;

  return (
    <Box mt={2}>
      {[Mode.PUBLIC_TRANSPORT, Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE].includes(selectedMode.mode as Mode) ? (
        publicRoutes?.map((route, publicIndex) => (
          <RouteCollapse key={route.resultId} route={route} publicIndex={publicIndex} handleDetails={showDetails} />
        ))
      ) : (
        <RouteCollapse route={individualRoute} handleDetails={showDetails} />
      )}
    </Box>
  );
}
