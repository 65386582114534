import { useState, useEffect } from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';

import { RouteLocationType } from '@/types/RouteLocationType';
import { useThrottle } from '@/hooks/useThrottle';
import { useMapStore } from '@/stores/useMapStore';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { LocationFeature } from '@/types/LocationFeature';
import { Geocoder } from '@/modules/Geocoder';

type GeocodingSearchInputProps = { label: string; routeLocationType: RouteLocationType };

export function GeocodingSearchInput({ label, routeLocationType }: GeocodingSearchInputProps) {
  const value = useRoutesStore((state) => state.routeLocations[routeLocationType]);
  const { updateRouteLocations } = useRoutesStore((state) => state.actions);

  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 300);
  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState<LocationFeature[]>([]);
  const [highlight, setHighlight] = useState<LocationFeature | null>(null);
  const { longitude, latitude } = useMapStore((state) => state.viewState);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    let componentMounted = true;

    if (focus && throttledInputValue !== '') {
      (async () => {
        const locations = await Geocoder.geocoding({ q: throttledInputValue, lon: longitude, lat: latitude });

        if (componentMounted) {
          setOptions(locations);
        }
      })();
    }

    return () => {
      componentMounted = false;
    };
  }, [value, throttledInputValue, focus, longitude, latitude]);

  const optionsIncludeValue = value && options.find((option) => Geocoder.areSame(option, value));
  const autocompleteOptions = [...options];

  if (value && !optionsIncludeValue) {
    autocompleteOptions.unshift(value);
  }

  const handleChange = (newLocation: LocationFeature | null) => {
    setFocus(!newLocation);
    updateRouteLocations({
      routeLocationType,
      location: newLocation || undefined,
    });
  };

  return (
    <Autocomplete
      getOptionLabel={({
        properties: {
          name: { full },
        },
      }) => full}
      isOptionEqualToValue={Geocoder.areSame}
      options={autocompleteOptions}
      autoComplete
      blurOnSelect
      includeInputInList
      autoSelect
      filterSelectedOptions={!optionsIncludeValue}
      filterOptions={(x) => x}
      forcePopupIcon={false}
      defaultValue={null}
      value={value || null}
      onChange={(event, newLocation) => handleChange(newLocation)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onHighlightChange={(event, option) => {
        setHighlight(option);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && event.currentTarget.querySelector('input') === document.activeElement) {
          handleChange(options[0]);
          event.currentTarget.querySelector('input')?.blur();
        }
        if (event.key === 'Tab') {
          const location = highlight || options[0];

          if (location && inputValue !== location.properties.name.firstLine) {
            event.preventDefault();
            setInputValue(location.properties.name.firstLine);
          }
        }
      }}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      renderInput={(params) => (
        <TextField sx={{ marginTop: 1 }} variant="standard" placeholder={label} {...params} fullWidth />
      )}
      renderOption={(props, location) => {
        const {
          properties: {
            name: { firstLine, secondLine },
          },
        } = location;

        const key = `${location.properties.osmId}-${location.properties.osmKey}`;

        return (
          <li {...props} key={key}>
            <Box>
              {firstLine}
              {secondLine && <Box sx={{ fontSize: '0.75rem', lineHeight: 1.1 }}>{secondLine}</Box>}
            </Box>
          </li>
        );
      }}
    />
  );
}
