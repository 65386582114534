import {
  Typography,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Link as MuiLink,
  styled,
  css,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { useUserStore } from '@/stores/useUserStore';
import VielmobilLogo from '@/assets/logos/vielmobil.svg?react';

const StyledDialog = styled(MuiDialog)(
  ({ theme }) => css`
    & .MuiDialog-paper {
      border-radius: ${theme.spacing(3)};
      background: ${theme.palette.primary.main};
    }

    text-align: center;
  `,
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-decoration: none;
    font-weight: bold;
    text-transform: none;
    background: white;
    border-radius: 24px;

    &.MuiButtonBase-root:hover {
      background-color: ${theme.palette.common.white};
      opacity: 0.5;
    }
  `,
);

const StyledLink = styled(MuiLink)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-decoration: none;
    font-weight: bold;
  `,
) as typeof MuiLink;

export function Dialog() {
  const dialogVisible = useUserStore((state) => state.dialogVisible);
  const mapVisible = useUserStore((state) => state.mapVisible);

  const { setDialogVisible, setMapVisible } = useUserStore((state) => state.actions);

  const handleClose = (event?: HTMLButtonElement | undefined, reason?: string) => {
    if (reason !== 'backdropClick') {
      setDialogVisible(false);
      setMapVisible(true);
    }
  };

  return (
    <StyledDialog maxWidth="sm" open={dialogVisible || !mapVisible}>
      <DialogTitle component="div">
        <Box mb={2}>
          <VielmobilLogo />
        </Box>
        <Typography variant="h1" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} mb={2}>
          Herzlich Willkommen auf dem Webportal für Alltagsmobilität in Frankfurt RheinMain!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          Vielmobil ist ein intermodales Auskunftssystem für die Region Frankfurt RheinMain. Die Berücksichtigung von
          stadt- und umweltsensitiven Verkehrslenkungsmaßnahmen befindet sich derzeit im Testbetrieb.
          <br />
          <StyledLink component={Link} to="/ueber-das-projekt">
            Mehr erfahren
          </StyledLink>
        </Typography>

        <Typography mb={2}>
          Für die Kartendarstellung nutzen wir Mapbox. Mit Klick auf &quot;Weiter&quot; stimmen Sie der Nutzung dieses
          Kartendienstes für Vielmobil und der Übertragung Ihrer IP-Adresse an einen Server von Mapbox in den USA zu.
          Weitere Informationen finden Sie unter{' '}
          <StyledLink component={Link} to="/datenschutz">
            Datenschutz
          </StyledLink>{' '}
          und im{' '}
          <StyledLink component={Link} to="/impressum">
            Impressum
          </StyledLink>
          .
        </Typography>

        <StyledButton size="large" onClick={() => handleClose()}>
          Weiter
        </StyledButton>
      </DialogContent>
    </StyledDialog>
  );
}
