import { Box, styled, css } from '@mui/material';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { RoutingForm } from '@/components/routing/RoutingForm';
import { RoutingModeSelect } from '@/components/routing/RoutingModeSelect';
import { RoutingResults } from '@/components/routing/RoutingResults';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: flex;
      flex-direction: column;
      width: ${theme.spacing(50)};
      background: ${theme.palette.primary.light};
    }
  `,
);

const StyledRouteBox = styled(Box)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: block;

      overflow-y: auto;
    }
    display: none;
    padding: ${theme.spacing(2)};
  `,
);

export function RoutingSidebar() {
  return (
    <StyledBox>
      <Header />
      <StyledRouteBox>
        <RoutingForm />
        <RoutingModeSelect />
        <RoutingResults />
      </StyledRouteBox>
      <Footer />
    </StyledBox>
  );
}
