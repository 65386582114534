import { Box, Stack, Typography, styled, css } from '@mui/material';

import { LevelOfServiceColors } from '@/modules/LevelOfServiceColors';
import { useMapStore } from '@/stores/useMapStore';

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    position: absolute;
    left: ${theme.spacing(1)};
    bottom: ${theme.spacing(10)};
    flex-direction: row;
    background: ${theme.palette.common.white};
    padding: ${theme.spacing(1)};

    ${theme.breakpoints.up('sm')} {
      left: auto;
      right: ${theme.spacing(1)};
      bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.up('md')} {
      top: ${theme.spacing(7)};
      left: ${theme.spacing(1)};
      bottom: auto;
      right: auto;
    }
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(4)};
    height: ${theme.spacing(1)};
    opacity: 0.75;
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    font-size: 0.8rem;
  `,
);

export function LevelOfServiceLegend() {
  const { trafficManagement } = useMapStore((state) => state.layersControl);

  return trafficManagement ? (
    <StyledStack>
      <StyledTypography mr={1}>schnell</StyledTypography>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        {[...LevelOfServiceColors].map(({ color }) => (
          <StyledBox key={color} sx={{ backgroundColor: color }} />
        ))}
      </Box>

      <StyledTypography ml={1}>langsam</StyledTypography>
    </StyledStack>
  ) : null;
}
