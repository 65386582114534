import { Marker } from 'react-map-gl';

import { RouteLocationType } from '@/types/RouteLocationType';
import { RouteLocationMarkerImage } from '@/components/map/RouteLocationMarkerImage';
import { Geocoder } from '@/modules/Geocoder';
import { useRoutesStore } from '@/stores/useRoutesStore';

export function RouteLocationMarkers() {
  const routeLocations = useRoutesStore((state) => state.routeLocations);
  const { updateRouteLocations } = useRoutesStore((state) => state.actions);

  return (
    <>
      {Object.values(RouteLocationType).map((routeLocationType) => {
        const routeLocation = routeLocations[routeLocationType];

        return routeLocation ? (
          <Marker
            key={routeLocationType}
            anchor="bottom"
            longitude={routeLocation.geometry.coordinates[0]}
            latitude={routeLocation.geometry.coordinates[1]}
            draggable
            onDragEnd={async (event) => {
              updateRouteLocations({
                routeLocationType,
                location: await Geocoder.reverse({
                  type: 'Point',
                  coordinates: [event.lngLat.lng, event.lngLat.lat],
                }),
              });
            }}
          >
            <RouteLocationMarkerImage markerType={routeLocationType} />
          </Marker>
        ) : null;
      })}
    </>
  );
}
