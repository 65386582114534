import { useState } from 'react';
import { Box, Collapse, Grid, styled, css, IconButton, useMediaQuery, useTheme } from '@mui/material';

import { ResultSummary } from '@/components/routing/ResultSummary';
import { Mode } from '@/types/Mode';
import DropdownArrow from '@/assets/icons/chevron-down-circle.svg?react';
import { transientOptions } from '@/utils/transient-options';
import { ModeIcon } from '@/components/routing/ModeIcon';
import { ResultDetailsWrapper } from '@/components/routing/ResultDetailsWrapper';
import { RouteStructure, TripLegStructure, TripResultStructure } from '@/modules/api-routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useRoutes } from '@/hooks/useRoutes';

const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{ $open?: boolean }>(
  ({ $open }) => css`
    transform: ${!$open && 'rotate(-90deg)'};
  `,
);

const StyledCircle = styled(
  Box,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    position: absolute;
    background-color: ${theme.palette.error.main};
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    top: 40px;
    right: ${theme.spacing(5)};
    left: ${!$open ? theme.spacing(5) : theme.spacing(4.5)};
    border: none;
    border-radius: 6px;
  `,
);

type RouteCollapseProps = {
  publicIndex?: number;
  handleDetails?: () => void;
} & (
  | {
      publicIndex?: undefined;
      route: RouteStructure | undefined;
    }
  | {
      publicIndex: number;
      route: TripResultStructure | undefined;
    }
);

export function RouteCollapse({ route, publicIndex, handleDetails }: RouteCollapseProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined | string>('');
  const [open, setOpen] = useState(false);
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const { updateSelectedMode } = useRoutesStore((state) => state.actions);

  const routes = useRoutes();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const alternativeRouteIncluded =
    routes &&
    selectedMode.mode &&
    routes[selectedMode.mode].data?.serviceDelivery?.deliveryPayload?.individualRouteResponse
      ?.individualRouteResponseContext?.situations?.roadSituation;

  const handleClick = (index: number | undefined | string) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <Box>
      {!route ? null : (
        <Collapse
          sx={{ marginBottom: theme.spacing(1.5) }}
          in={publicIndex === selectedIndex}
          collapsedSize={publicIndex !== undefined ? 71 : 64}
          orientation="vertical"
        >
          <Grid container sx={{ background: theme.palette.primary.light, border: 'none' }}>
            <Grid item xs={2}>
              {selectedMode.index === publicIndex &&
              selectedMode.mode &&
              [Mode.PUBLIC_TRANSPORT, Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE].includes(selectedMode.mode) ? (
                <ModeIcon variant="circle-filled" mode={selectedMode.mode} />
              ) : (
                <ModeIcon variant="circle-border" mode={selectedMode.mode} />
              )}
            </Grid>
            <Grid item xs={8} sx={{ textAlign: 'left' }}>
              {publicIndex !== undefined ? (
                <ResultSummary
                  publicMode
                  duration={route?.trip?.duration}
                  departure={route?.trip?.startTime}
                  arrival={route?.trip?.endTime}
                  interchange={route?.trip?.interchanges}
                  index={publicIndex}
                  changeModes={route?.trip?.tripLeg
                    ?.filter((timedLeg: TripLegStructure) => timedLeg.timedLeg?.service?.publishedLineName?.text)
                    .map((timedLeg: TripLegStructure) => timedLeg?.timedLeg?.service?.publishedLineName?.text)}
                />
              ) : (
                <ResultSummary
                  publicMode={false}
                  duration={route?.duration}
                  departure={route?.startTime}
                  distance={route?.distance}
                  algorithm={route?.extension?.any.value.modeMetaInfo[0].tripModeAlgorithm.individualAlgorithm[0]}
                />
              )}
            </Grid>
            <Grid item xs={2} sx={{ position: 'relative', top: theme.spacing(-1.5) }}>
              <StyledIconButton
                onClick={() => {
                  updateSelectedMode({ index: publicIndex });

                  handleClick(publicIndex);

                  setOpen(!open);
                  if (matches && handleDetails) {
                    handleDetails();
                  }
                }}
                $open={open}
              >
                {alternativeRouteIncluded && selectedMode.mode === Mode.CAR && <StyledCircle $open={open} />}
                <DropdownArrow />
              </StyledIconButton>
            </Grid>
            <ResultDetailsWrapper route={route} />
          </Grid>
        </Collapse>
      )}
    </Box>
  );
}
