/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Trias } from '../models/Trias';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoutingAPI {

    /**
     * Accepts TRIAS IndividualRouteRequest and returns TRIAS IndividualRouteResponse
     * Accepts an IndividualRouteRequest of a TRIAS service.
     * @returns Trias OK. TRIAS TripRequest is processed.
     * @throws ApiError
     */
    public static getIndividualRoutes({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-individual-route-trias',
            body: triasRequest,
            errors: {
                400: `BAD REQUEST - see more detailed content within the TRIAS error response!`,
                404: `The resource could not be found!!!`,
                500: `Internal Server Error of unknown reason; please see the TRIAS error message`,
            },
        });
    }

    /**
     * Accepts TRIAS TripRequest and returns TRIAS TripResponse
     * Accepts an intermodal TripRequest of a TRIAS service IndividualRouteRequest including TraffiCon extensions.</br>Returns a CSV String entity holding indikatoren values.
     * @returns Trias OK. TRIAS TripRequest is processed.
     * @throws ApiError
     */
    public static getIntermodalTrips({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-intermodal-route-trias',
            body: triasRequest,
            errors: {
                400: `BAD REQUEST - see more detailed content within TRIAS error response!`,
                404: `The resource could not be found!!!`,
                500: `Internal Server Error, hence there is some sample response data.`,
            },
        });
    }

    /**
     * Accepts TRIAS routing request and returns CSV (String) response
     * Accepts a 'special' time interval-based request of a TRIAS service IndividualRouteRequest including TraffiCon extensions.</br>Returns a CSV String entity holding indikatoren values.
     * @returns string OK. TRIAS TripRequest is processed.
     * @throws ApiError
     */
    public static getRoutes({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-route-indikatoren',
            body: triasRequest,
            errors: {
                400: `BAD REQUEST - see more detailed content within TRIAS error response!`,
                404: `The resource could not be found!!!`,
                500: `Internal Server Error, hence there is some sample response data.`,
            },
        });
    }

    /**
     * @deprecated
     * DEPRECATED! Will be deactivated soon! Accepts boath a TRIAS TripRequest and RouteRequest and returns XML (TRIAS) response
     * Accepts a 'special' time interval-based request of a TRIAS service IndividualRouteRequest including TraffiCon extensions.</br>Returns a CSV String entity holding indikatoren values.
     * @returns Trias OK
     * @throws ApiError
     */
    public static requestRouteTriasUsingPost({
        triasRequest,
    }: {
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-route-trias',
            body: triasRequest,
        });
    }

}
