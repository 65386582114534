import { ListItemIcon, MenuItem, MenuList } from '@mui/material';
import { Popup } from 'react-map-gl';

import MarkerOrigin from '@/assets/icons/map/route-locations/origin.svg?react';
import MarkerDestination from '@/assets/icons/map/route-locations/destination.svg?react';
import { RouteLocationType } from '@/types/RouteLocationType';
import { Geocoder } from '@/modules/Geocoder';
import { useRoutesStore } from '@/stores/useRoutesStore';

type ContextPopUpProps = {
  longitude?: number;
  latitude?: number;
  onClose: () => void;
};

export function ContextMenuMarker({ longitude, latitude, onClose }: ContextPopUpProps) {
  const { updateRouteLocations } = useRoutesStore((state) => state.actions);

  return longitude !== undefined && latitude !== undefined ? (
    <Popup longitude={longitude} latitude={latitude} closeOnClick closeOnMove closeButton={false} onClose={onClose}>
      <MenuList>
        {[
          { Icon: MarkerOrigin, label: 'Start setzen', routeLocationType: RouteLocationType.ORIGIN },
          { Icon: MarkerDestination, label: 'Ziel setzen', routeLocationType: RouteLocationType.DESTINATION },
        ].map(({ Icon, label, routeLocationType }) => (
          <MenuItem
            key={routeLocationType}
            onClick={async () => {
              updateRouteLocations({
                location: await Geocoder.reverse({ type: 'Point', coordinates: [longitude, latitude] }),
                routeLocationType,
              });

              onClose();
            }}
            sx={{ color: (theme) => `${theme.palette.text.secondary}` }}
          >
            <ListItemIcon sx={{ marginRight: 2 }}>
              <Icon />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Popup>
  ) : null;
}
