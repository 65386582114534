import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { MapState } from '@/types/MapState';

export const useMapStore = create<MapState>()(
  persist(
    (set) => ({
      viewState: {
        longitude: 8.676973,
        latitude: 50.11756,
        zoom: 9,
      },

      layersControl: {
        trafficReport: false,
        trafficManagement: false,
      },

      actions: {
        setViewState: (viewState) => set({ viewState }),

        updateLayersControl: (newLayersControl) =>
          set(({ layersControl }) => ({
            layersControl: {
              ...layersControl,
              ...newLayersControl,
            },
          })),
      },
    }),
    {
      name: 'vielmobil.info/map',
      version: 1,
      partialize: ({ actions, viewState, ...rest }) => rest,
    },
  ),
);
