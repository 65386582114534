import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type PopupTitleProps = { children: ReactNode };

export function PopupTitle({ children }: PopupTitleProps) {
  return children ? (
    <Typography fontWeight="bold" mb={1} sx={{ fontSie: '0.75rem' }}>
      {children}
    </Typography>
  ) : null;
}
