import { useEffect, useState } from 'react';
import { Box, css, Fade, IconButton, Slide, styled, useMediaQuery, useTheme } from '@mui/material';
import { useMatch } from 'react-router-dom';

import PopOverIcon from '@/assets/icons/chevron-up-circle.svg?react';
import { RoutingForm } from '@/components/routing/RoutingForm';
import { RoutingModeSelect } from '@/components/routing/RoutingModeSelect';
import { RoutingResults } from '@/components/routing/RoutingResults';
import { Mode } from '@/types/Mode';
import { ResultDetailsWrapper } from '@/components/routing/ResultDetailsWrapper';
import NextArrowIcon from '@/assets/icons/chevron-down-circle.svg?react';
import { RouteLocationType } from '@/types/RouteLocationType';
import { TriasService } from '@/modules/TriasService';
import { RoutingStep } from '@/types/RoutingStep';
import { transientOptions } from '@/utils/transient-options';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { useRoutes } from '@/hooks/useRoutes';

const StyledFixedBox = styled(
  Box,
  transientOptions,
)<{ $zIndex: number }>(
  ({ theme, $zIndex }) => css`
    position: fixed;
    top: ${theme.spacing(7)};
    left: 0;
    right: 0;
    z-index: ${$zIndex};

    ${theme.breakpoints.up('sm')} {
      top: ${theme.spacing(8)};
    }
  `,
);

const StyledPreviousButton = styled(
  IconButton,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) ${!$open && 'rotate(-180deg)'};
    transition: ${theme.transitions.create('transform')};
    z-index: 101;
  `,
);

const StyledNextButton = styled(IconButton)(css`
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`);

const StyledContentBox = styled(Box)(
  ({ theme }) => css`
    max-height: calc(100vh - ${theme.spacing(7)});
    overflow: scroll;
    background: ${theme.palette.primary.light};
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('sm')} {
      max-height: calc(100vh - ${theme.spacing(8)});
    }
  `,
);

export function RoutingMobile() {
  const [routingStep, setRoutingStep] = useState(RoutingStep.CLOSED);

  const match = useMatch('/');

  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const selectedMode = useRoutesStore((state) => state.selectedMode);

  const routes = useRoutes();

  const individualRoute =
    routes && selectedMode.mode && TriasService.getIndividualRoute(routes[selectedMode.mode].data);
  const publicRoute = selectedMode.mode && TriasService.getIntermodalRoute(routes[selectedMode.mode].data);

  const handleClickPrevious = () => {
    setRoutingStep((prevState) => (prevState === RoutingStep.CLOSED ? prevState + 1 : prevState - 1));
  };
  const handleClickNext = () => {
    setRoutingStep((prevState) => prevState + 1);
  };

  const showDetails = () => {
    setRoutingStep(RoutingStep.RESULT_DETAILS);
  };

  const selectedModeIsFetching = selectedMode.mode && routes[selectedMode.mode].isFetching;
  useEffect(() => {
    if (routingStep > RoutingStep.MODE_SELECT && selectedMode.mode && selectedModeIsFetching) {
      setRoutingStep(RoutingStep.MODE_SELECT);
    }
  }, [routingStep, selectedModeIsFetching, selectedMode.mode]);

  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down('md'));

  return show && match ? (
    <>
      <StyledFixedBox $zIndex={101}>
        <StyledPreviousButton onClick={handleClickPrevious} $open={routingStep !== RoutingStep.CLOSED}>
          <PopOverIcon />
        </StyledPreviousButton>
      </StyledFixedBox>

      <Slide in={routingStep !== RoutingStep.CLOSED}>
        <StyledFixedBox $zIndex={99}>
          <StyledContentBox>
            {routingStep === RoutingStep.FORM && <RoutingForm />}
            {routingStep === RoutingStep.MODE_SELECT && <RoutingModeSelect />}
            {routingStep === RoutingStep.RESULTS && <RoutingResults showDetails={showDetails} />}
            {routingStep === RoutingStep.RESULT_DETAILS &&
              publicRoute &&
              (selectedMode.mode === Mode.PUBLIC_TRANSPORT ||
                selectedMode.mode === Mode.BIKE_AND_RIDE ||
                selectedMode.mode === Mode.PARK_AND_RIDE) && <ResultDetailsWrapper mobile route={publicRoute} />}
            {routingStep === RoutingStep.RESULT_DETAILS && individualRoute && (
              <ResultDetailsWrapper mobile route={individualRoute} />
            )}
          </StyledContentBox>

          <Fade
            in={
              (routingStep === RoutingStep.FORM && !!origin && !!destination) ||
              (routingStep === RoutingStep.MODE_SELECT &&
                !!selectedMode.mode &&
                !!(
                  TriasService.getIndividualRoute(routes[selectedMode.mode].data) ||
                  TriasService.getIntermodalRoute(routes[selectedMode.mode].data)
                ))
            }
          >
            <StyledNextButton onClick={handleClickNext}>
              <NextArrowIcon />
            </StyledNextButton>
          </Fade>
        </StyledFixedBox>
      </Slide>
    </>
  ) : null;
}
