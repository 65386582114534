import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UserState } from '@/types/UserState';

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      dialogVisible: true,
      mapVisible: false,

      actions: {
        setDialogVisible: (dialogVisible) => set({ dialogVisible }),
        setMapVisible: (mapVisible) => set({ mapVisible }),
      },
    }),
    {
      name: 'vielmobil.info/user',
      version: 1,
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
);
