import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

import { ClickEventFeature } from '@/types/ClickEventFeature';

export class GeoServer {
  private static makeUrl(path?: string) {
    return decodeURI(new URL(`${path}`, import.meta.env.VITE_GEOSERVER_URL).toString());
  }

  public static wms({ layers }: { layers: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WMS',
      version: '1.1.0',
      request: 'GetMap',
      layers,
      format: 'image/png',
      width: '256',
      height: '256',
      transparent: 'true',
      bbox: '{bbox-epsg-3857}',
      srs: 'EPSG:3857',
    });

    return GeoServer.makeUrl(`wms?${urlSearchParams}`);
  }

  public static wmts({ layer }: { layer: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WMTS',
      version: '1.0.0',
      request: 'GetTile',
      layer,
      format: 'application/vnd.mapbox-vector-tile',
      tilematrixset: 'EPSG:900913',
      tilecol: '{x}',
      tilerow: '{y}',
      tilematrix: 'EPSG:900913:{z}',
    });

    return GeoServer.makeUrl(`gwc/service/wmts?${urlSearchParams}`);
  }

  public static wfs({ typeName }: { typeName: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName,
      outputFormat: 'application/json',
    });

    return GeoServer.makeUrl(`ows?${urlSearchParams}`);
  }

  public static async getFeatureInfo<G extends Geometry, P extends GeoJsonProperties>({
    layers,
    feature: {
      properties: {
        point,
        map: { width, height, bounds },
      },
    },
  }: {
    layers: string;
    feature: ClickEventFeature;
  }): Promise<FeatureCollection<G, P>> {
    const urlSearchParams = new URLSearchParams({
      service: 'WMS',
      version: '1.1.1',
      request: 'GetFeatureInfo',
      info_format: 'application/json',
      layers,
      query_layers: layers,
      width: (width / window.devicePixelRatio).toString(),
      height: (height / window.devicePixelRatio).toString(),
      srs: 'EPSG:4326',
      bbox: bounds
        .toArray()
        .flat()
        .map((value) => value.toString())
        .join(','),
      x: Math.round(point.x).toString(),
      y: Math.round(point.y).toString(),
    });

    const response = await fetch(GeoServer.makeUrl(`ows?${urlSearchParams}`));

    return response.json();
  }
}
